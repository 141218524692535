import React, {FC, ReactElement} from 'react';
import {Navigate, Routes, Route} from "react-router-dom";
import LoginPage from "./pages/Login";
import Users from "./pages/Users";
import Articles from "./pages/Articles";
import QuotesPage from "./pages/Quotes";
import SettingsPage from "./pages/Settings";
import SubscriptionsPage from "./pages/SubscriptionsPage";
import Article from "./pages/Article";
import UserPage from "./pages/User";
import BasicLayout from "./containers/Layout/Layout";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import Quote from "./pages/Quote";
import QuotePage from "./pages/Quote";

const  Protected: FC<{isSignedIn: boolean, children: ReactElement}> = ({ isSignedIn, children }) => {
  if (!isSignedIn) {
    return <Navigate to="/login" replace />
  }
  return children
}

const isSignedIn = (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') as boolean;

const privateRouters = [
  {
    path: "/users",
    element: <Protected isSignedIn={isSignedIn}><Users /></Protected>,
  },
  {
    path: "/user/:id",
    element: <Protected isSignedIn={isSignedIn}><UserPage /></Protected>,
  },
  {
    path: "/user",
    element: <Protected isSignedIn={isSignedIn}><UserPage /></Protected>,
  },
  {
    path: "/article",
    element: <Protected isSignedIn={isSignedIn}><Article /></Protected>,
  },
  {
    path: "/article/:id",
    element: <Protected isSignedIn={isSignedIn}><Article /></Protected>,
  },
  {
    path: "/articles",
    element: <Protected isSignedIn={isSignedIn}><Articles /></Protected>,
  },
  {
    path: "/quotes",
    element: <Protected isSignedIn={isSignedIn}><QuotesPage /></Protected>,
  },
  {
    path: "/quote/:id",
    element: <Protected isSignedIn={isSignedIn}><QuotePage /></Protected>,
  },
  {
    path: "/settings",
    element: <Protected isSignedIn={isSignedIn}><SettingsPage /></Protected>,
  },
  {
    path: "/subscriptions",
    element: <Protected isSignedIn={isSignedIn}><SubscriptionsPage /></Protected>,
  }
];
const queryClient = new QueryClient()

const App = () =>  {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<BasicLayout />}>
            {privateRouters.map(elem => (<Route path={elem.path} element={elem.element} />))}
          </Route>
          <Route path="*" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
