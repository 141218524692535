import {Button, Col, Row, Space, Switch, Table} from "antd";
import {TArticle, TQuote} from "../helpers/types";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getAllArticles, removeArticle, updateArticleIsPublished} from "../services/article";
import {useNavigate} from "react-router-dom";

const Articles = () => {

  const queryClient = useQueryClient();

  const articleMutation = useMutation({
    mutationFn: (data: any) => updateArticleIsPublished(data.id, data.isPublished),
    onSuccess: data => {
      queryClient.setQueryData(['articles'], (oldData?: TQuote[]) => {
        if(oldData) {
          return oldData.map((elem: TQuote) => {
            return elem.id === data.id ? {...elem, ...data} : elem
          })
        }

        return oldData
      });
    }
  })

  const articleDeleteMutation = useMutation({
    mutationFn: (data: any) => removeArticle(data.id),
    onSuccess: data => {
      queryClient.setQueryData(['articles'], (oldData?: TQuote[]) => {
        if(oldData) {
          return oldData.filter((elem: TQuote) => {
            return elem.id !== data.id
          })
        }

        return oldData
      });
    }
  })

  const onChange = (id: string, isPublished: boolean) => {
    articleMutation.mutate({
      id,
      isPublished
    })
  }

  const onDelete = (id: string) => {
    articleDeleteMutation.mutate({
      id
    })
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Категория',
      dataIndex: ['category','name'],
      key: 'category',
    },
    {
      title: 'Подкатегория',
      dataIndex: ['subType', 'name'],
      key: 'subType',
    },
    {
      title: 'Публикация',
      key: 'isPublished',
      render: (_: any, record: TArticle) => (
        <Space size="middle">
          <Switch checked={record.isPublished} onChange={() => onChange(record.id, !record.isPublished)} />
        </Space>
      ),
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_: any, record: TArticle) => (
        <Space size="middle">
          <a href={`/article/${record.id}`}>Редактировать</a>
          <Button type="link" onClick={() => onDelete(record.id)}>
            Удалить
          </Button>
        </Space>
      ),
    }
  ];

  const navigate = useNavigate();

  const {data, status} = useQuery({
    queryKey: ["articles"],
    queryFn: getAllArticles
  })

  return <>
    <Row>
      <Col span={4} offset={20}>
        <div style={{display: 'flex', justifyContent: 'end', marginBottom: '20px'}}>
          <Button type="primary" onClick={() => navigate('/article')}>Добавить статью</Button>
        </div>
      </Col>
    </Row>

    <Table dataSource={data} columns={columns} />
    </>
}

export default Articles;
