import API from "../helpers/api";
import {TArticle} from "../helpers/types";

export const getAllArticles = () => API.get('/articles').then((response:any): TArticle[] => response.data.data)
export const getArticleById = (id?: string) => API.get(`/articles/${id}`).then((response:any): TArticle => response.data)
export const updateArticle = (id: string, updatedData: TArticle) => {
  if(updatedData.dragger && updatedData.dragger.length > 0) {
    updatedData.image = updatedData.dragger[0].response.filename
  }
  return API.put(`/articles/${id}`, updatedData).then((response:any): TArticle => response.data)
}
export const addArticle = (data: TArticle) => {
  if(data.dragger && data.dragger.length > 0) {
    data.image = data.dragger[0].response.filename
  }
  return API.post(`/articles/`, data).then((response:any): TArticle => response.data)
}
export const updateArticleIsPublished = (id: string, isPublished: boolean) => API.put(`/articles/${id}/published`, {isPublished}).then((response:any): TArticle => response.data)
export const removeArticle = (id: string) => API.delete(`/articles/${id}`).then((response:any) => response.data)
