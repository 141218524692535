import { Button, Form, Input, Select, Switch, Upload, message } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addArticle, getArticleById, updateArticle } from "../services/article";
import { getAllCategories } from "../services/category";
import { getAllSubTypes } from "../services/subType";
import { useEffect, useState } from "react";
import { TArticle } from "../helpers/types";

const config = {
  // baseURL: 'http://localhost:4000'
  baseURL: `https://admin-4-lm.com/admin`
}

const Article = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    if (!id) {
      form.setFieldsValue({ isSendPush: false });
    }
  }, [])

  const { data, status } = useQuery({
    queryKey: ["article", id],
    queryFn: () => {
      return id ? getArticleById(id) : null
    }
  })

  const beforeUpload = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'|| file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error('Только PNG, JPEG, и JPG файлы разрешены к загрузке');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('Изображение должно быть меньше 10 MB');
    }
    return (isJpgOrPng && isLt2M) || Upload.LIST_IGNORE;
  };

  const { data: categoryData, status: categoryStatus } = useQuery({
    queryKey: ["categories"],
    queryFn: getAllCategories
  })

  const { data: subTypeData, status: subTypeStatus } = useQuery({
    queryKey: ["subtypes"],
    queryFn: getAllSubTypes
  })

  useEffect(() => {
    if (data) {
      if (data.image) {
        setImage(data.image)
      }

      form.setFieldsValue({
        ...data
      })
    }
  }, [data])

  const articleMutation = useMutation({
    mutationFn: (values: TArticle) => updateArticle(id || '', values),
    onSuccess: data => {
      queryClient.setQueryData(['article', { id }], data);
      queryClient.setQueryData(['articles'], (oldData?: TArticle[]) => {
        if (oldData) {
          return oldData.map((elem: TArticle) => {
            return elem.id === id ? {
              ...elem, ...data,
              category: categoryData?.find(category => category.id === data.categoryId),
              subType: subTypeData?.find(subType => subType.id === data.subTypeId)
            } : elem
          })
        }

        return oldData
      });
    }
  })

  const addArticleMutation = useMutation({
    mutationFn: (values: TArticle) => addArticle(values),
    onSuccess: data => {
      queryClient.setQueryData(['articles'], (oldData?: TArticle[]) => {
        if (oldData) {
          return [{
            ...data,
            category: categoryData?.find(category => category.id === data.categoryId),
            subType: subTypeData?.find(subType => subType.id === data.subTypeId)
          }, ...oldData]
        }

        return oldData
      });
    }
  })

  const onFinish = (values: any) => {
    if (id) {
      articleMutation.mutate(values)
    } else {
      addArticleMutation.mutate(values)
    }

    navigate('/articles')
  };

  const getUploadAction = () => {
    return `${config.baseURL}/articles/upload`
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  console.log('image', image);

  // @ts-ignore
  return <Form
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 14 }}
    layout="vertical"
    form={form}
    name="article-form"
    onFinish={onFinish}
  >

    <Form.Item name={'title'} label="Заголовок" rules={[{ required: true, message: 'Это поле обязательное' }]}>
      <Input />
    </Form.Item>
    <Form.Item label="Выбор категории" name={'categoryId'} rules={[{ required: true, message: 'Это поле обязательное' }]}>
      <Select>
        {categoryData?.map(category => (<Select.Option value={category.id}>{category.name}</Select.Option>))}
      </Select>
    </Form.Item>
    <Form.Item label="Выбор фильтра" name={'subTypeId'} rules={[]}>
      <Select>
        {subTypeData?.map(subType => (<Select.Option value={subType.id}>{subType.name}</Select.Option>))}
      </Select>
    </Form.Item>
    <Form.Item label="Текст" name={'description'}
      rules={[{ required: true, message: 'Это поле обязательное' }]}
      valuePropName='data'
      getValueFromEvent={(event, editor) => {
        const data = editor.getData();
        return data;
      }}
    >
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
        }}
        data=""
        onReady={editor => {
          // You can store the "editor" and use when it is needed.
          console.log('Editor is ready to use!', editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          form.setFieldsValue({ description: data });
          console.log({ event, editor, data });
        }}
        onBlur={(event, editor) => {
          console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          console.log('Focus.', editor);
        }}
      />
    </Form.Item>
    <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile}>
      <Upload.Dragger name="file"
        beforeUpload={beforeUpload}
        accept=".png,.jpeg,.jpg"
        action={getUploadAction}
        multiple={false}
        maxCount={1}
        defaultFileList={image ?
          [{ uid: 'image_uploaded', name: image, url: image, status: 'done' }] : undefined}>
        <div style={{ minHeight: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div>Загрузить изображение</div>
        </div>
      </Upload.Dragger>
    </Form.Item>
    <Form.Item label="Отправлять Push-оповещение" valuePropName="checked" name={'isSendPush'}>
      <Switch />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">{id ? 'Редактировать статью' : 'Добавить статью'}</Button>
    </Form.Item>
  </Form>
}

export default Article;
