import {Button, Form, Input, Select} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getUserById, updateUser} from "../services/user";
import {useEffect} from "react";
import {TUser} from "../helpers/types";

const UserPage = () => {

  let { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const {data, status} = useQuery({
    queryKey: ["user", id],
    queryFn: () => {
      return id ? getUserById(id) : null
    }
  });

  const userMutation = useMutation({
    mutationFn: (values: TUser) => updateUser(id || '', values),
    onSuccess: data => {
      queryClient.setQueryData(['user', { id }], data);
      queryClient.setQueryData(['users'], (oldData?: TUser[]) => {
        if(oldData) {
          return oldData.map((elem: TUser) => {
            return elem.id === id ? {...elem, ...data} : elem
          })
        }

        return oldData
      });
    }
  })

  useEffect(() => {
    if(data) {
      form.setFieldsValue({
        ...data
      })
    }
  }, [data])

  const onFinish = (values: TUser) => {
    if(id) {
      userMutation.mutate(values)
      navigate('/users')
    }
  };

  return <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="vertical"
      form={form}
      name="user-form"
      onFinish={onFinish}
    >

      <Form.Item label="Имя" name={'firstName'}>
        <Input />
      </Form.Item>
      <Form.Item label="Фамилия" name={'lastName'}>
        <Input />
      </Form.Item>
      <Form.Item label="Почтовый адрес" name={'email'} rules={[{ required: true, message: 'Это поле обязательное' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Роль" name={'role'} rules={[{ required: true, message: 'Это поле обязательное' }]}>
        <Select>
          <Select.Option value="user">Пользователь</Select.Option>
          <Select.Option value="editor">Редактор</Select.Option>
          <Select.Option value="admin">Администратор</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Редактировать пользователя</Button>
      </Form.Item>
    </Form>
}

export default UserPage;
