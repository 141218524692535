import React, {FC, ReactNode, useEffect, useState} from "react";
import {Button, Layout, Menu, MenuProps, theme} from "antd";
import {
  MenuFoldOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  FileOutlined,
  AreaChartOutlined,
  SettingOutlined,
  AuditOutlined
} from '@ant-design/icons';
import {Outlet, useLocation, useNavigate} from "react-router-dom";

const { Header, Content, Sider, Footer } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  onClick?: () => void | null,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick
  } as MenuItem;
}



type TProps = {
  children?: ReactNode
}

const BasicLayout: FC<TProps> = ({children}) => {

  const [collapsed, setCollapsed] = useState(false);
  const [selectedValue, setSelectedValue] = useState('0');
  const navigate = useNavigate();
  const location = useLocation();

  const routes = [{
    id: '1',
    name: 'Статьи',
    icon: <FileOutlined />,
    onClick: () => navigate('/articles'),
    pathName: '/articles'
  }, {
    id: '2',
    name: 'Котировки',
    icon: <AreaChartOutlined />,
    onClick: () => navigate('/quotes'),
    pathName: '/quotes'
  }, {
    id: '3',
    name: 'Пользователи',
    icon: <UserOutlined />,
    onClick: () => navigate('/users'),
    pathName: '/users'
  }, {
    id: '4',
    name: 'Подписки',
    icon: <AuditOutlined />,
    onClick: () => navigate('/subscriptions'),
    pathName: '/subscriptions'
  }, {
    id: '5',
    name: 'Настройки',
    icon: <SettingOutlined />,
    onClick: () => navigate('/settings'),
    pathName: '/settings'
  }];

  useEffect(() => {
    const selectedRoute = routes.find(route => route.pathName === location.pathname)
    setSelectedValue(selectedRoute?.id || '1')
  }, [location.pathname])


  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onLogOut = () => {
    localStorage.removeItem('accessToken');
    navigate('/login')
  }

  const items: MenuItem[] = routes.map(route => getItem(route.name, route.id, route.icon, route.onClick))

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Menu theme="dark" mode="inline" items={items} selectedKeys={[selectedValue]} />
        <Button type="primary" danger
                style={{position: 'absolute', bottom: '10px', width: '90%', left: collapsed ? '2px': '10px'}}
                onClick={onLogOut}>
          Выйти
        </Button>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content style={{ margin: '0 16px' }}>

          <div style={{ display: 'flex', marginTop: 24, padding: 24, minHeight: 360, background: colorBgContainer }}>
            <div style={{width: '100%'}}>
              <Outlet />
            </div>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>4LM Design ©2023 Created by 4LM</Footer>
      </Layout>
    </Layout>
  );
}

export default BasicLayout;
