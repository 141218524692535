import {Space, Table} from "antd";
import {useQuery} from "@tanstack/react-query";
import {TUser} from "../helpers/types";
import {getAllUsers} from "../services/user";

const Users = () => {

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Фамилия',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Почтовый адрес',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Действие',
      key: 'actions',
      render: (_: any, record: TUser) => (
        <Space size="middle">
          <a href={`/user/${record.id}`}>Редактировать</a>
        </Space>
      ),
    }
  ];

  const {data, status} = useQuery({
    queryKey: ["users"],
    queryFn: getAllUsers
  });

  return <Table dataSource={data} columns={columns} />;
}
export default Users;
