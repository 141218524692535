import {Button, Form, Input, Select} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {addArticle} from "../services/article";
import {getAllCategories} from "../services/category";
import {getAllSubTypes} from "../services/subType";
import {useEffect} from "react";
import {TQuote} from "../helpers/types";
import {getQuoteById, updateQuote} from "../services/quote";

const QuotePage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const {data, status} = useQuery({
    queryKey: ["quote", id],
    queryFn: () => {
      return id ? getQuoteById(id) : null
    }
  })

  const {data: categoryData, status: categoryStatus} = useQuery({
    queryKey: ["categories"],
    queryFn: getAllCategories
  })

  const {data: subTypeData, status: subTypeStatus} = useQuery({
    queryKey: ["subtypes"],
    queryFn: getAllSubTypes
  })

  useEffect(() => {
    if(data) {
      form.setFieldsValue({
        ...data
      })
    }
  }, [data])

  const quoteMutation = useMutation({
    mutationFn: (values: TQuote) => updateQuote(id || '', values),
    onSuccess: data => {
      queryClient.setQueryData(['quote', { id }], data);
      queryClient.setQueryData(['quotes'], (oldData?: TQuote[]) => {
        if(oldData) {
          return oldData.map((elem: TQuote) => {
            return elem.id === id ? {...elem, ...data,
              category: categoryData?.find(category => category.id === data.categoryId),
              subType: subTypeData?.find(subType => subType.id === data.subTypeId)
            } : elem
          })
        }

        return oldData
      });
    }
  })

  const onFinish = (values: any) => {
    quoteMutation.mutate(values)
    navigate('/quotes')
  };

  return <Form
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 14 }}
    layout="vertical"
    form={form}
    name="quote-form"
    onFinish={onFinish}
  >

    <Form.Item name={'contractName'} label="Котировка" rules={[{ required: true, message: 'Это поле обязательное' }]}>
      <Input disabled={true}/>
    </Form.Item>
    <Form.Item label="Выбор категории" name={'categoryId'} rules={[{ required: true, message: 'Это поле обязательное' }]}>
      <Select>
        {categoryData?.map(category => (<Select.Option value={category.id}>{category.name}</Select.Option>))}
      </Select>
    </Form.Item>
    <Form.Item label="Выбор фильтра" name={'subTypeId'} rules={[]}>
      <Select>
        {subTypeData?.map(subType => (<Select.Option value={subType.id}>{subType.name}</Select.Option>))}
      </Select>
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">{'Редактировать контракт'}</Button>
    </Form.Item>
  </Form>
}

export default QuotePage;
