import {Space, Switch, Table} from "antd";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getAllQuotes, updateQuoteStatus} from "../services/quote";
import {TQuote} from "../helpers/types";

const QuotesPage = () => {

  const queryClient = useQueryClient();

  const quoteMutation = useMutation({
    mutationFn: (data: any) => updateQuoteStatus(data.id, data.status),
    onSuccess: data => {
      queryClient.setQueryData(['quotes'], (oldData?: TQuote[]) => {
        if(oldData) {
          return oldData.map((elem: TQuote) => {
            return elem.id === data.id ? {...elem, ...data} : elem
          })
        }

        return oldData
      });
    }
  })

  const onChange = (id: string, status: boolean) => {
    quoteMutation.mutate({
      id,
      status
    })
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Котировка',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Контракт',
      dataIndex: 'contractName',
      key: 'contractName',
    },
    {
      title: 'Значение',
      dataIndex: 'lastPrice',
      key: 'lastPrice',
    },
    {
      title: 'Дельта',
      dataIndex: 'deltaChange',
      key: 'deltaChange',
    },
    {
      title: 'Дата истечения',
      dataIndex: 'expDate',
      key: 'expDate',
    },
    {
      title: 'Провайдер',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Публикация',
      key: 'status',
      render: (_: any, record: TQuote) => {
        return (
          <Space size="middle">
            <Switch checked={record.status} onChange={() => onChange(record.id, !record.status)} />
          </Space>
        )
      },
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_: any, record: TQuote) => (
        <Space size="middle">
          <a href={`/quote/${record.id}`}>Редактировать</a>
        </Space>
      ),
    }
  ];

  const {data, status} = useQuery({
    queryKey: ["quotes"],
    queryFn: getAllQuotes
  })

  return <Table dataSource={data} columns={columns} pagination={false} />;
}

export default QuotesPage;
